export default {
    FUNDS(state, funds) {
        state.funds = funds;
    },
    FUND_DETAILS(state, fundDetails) {
        state.fundDetails = fundDetails;
    },
    TOP_HOLDINGS(state, topHoldings) {
        state.topHoldings = topHoldings;
    },
    NAV_MARKET(state, navMarket) {
        state.navMarket = navMarket;
    },
    PREMIUM_DISCOUNT(state, premiumDiscount) {
        state.premiumDiscount = premiumDiscount;
    }
};
