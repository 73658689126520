export default {
    funds: state => state.funds,
    fundDetail: state => ticker => {
        return state.fundDetails[ticker];
    },
    topHoldings: state => ticker => {
        return state.topHoldings[ticker];
    },
    navMarket: state => state.navMarket,
    premiumDiscount: state => state.premiumDiscount
};
