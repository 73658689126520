<template>
    <div class="md:overflow-x-auto md:scrolling-auto mt-4">
        <table class="dist-caps-block table-auto w-full mb-6">
            <thead>
                <tr class="header-row">
                    <td class="w-1/8 px-4 py-2 align-bottom">
                        <strong>Number of days at</strong>
                    </td>
                    <td
                        v-for="(column, cindex) in columns"
                        :key="cindex"
                        class="w-1/8 px-4 py-2 align-bottom"
                    >
                        <strong>{{ column }}</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(row, index) in numberOfDays"
                    :key="index"
                    :class="{ 'bg-gray-100': index % 2 == 0 }"
                >
                    <td class="border px-4 py-2">
                        {{ getTypeName(row.type) }}
                    </td>
                    <td
                        v-for="(item, idx) in row.data"
                        :key="idx"
                        class="border px-4 py-2"
                    >
                        {{ item.value }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import collect from 'collect.js';

export default {
    props: ['ticker'],
    computed: {
        ...mapGetters('funds', ['fundDetail']),
        fund() {
            return this.fundDetail(this.ticker);
        },
        numberOfDays() {
            return this.fund ? this.fund.number_of_days : null;
        },
        columns() {
            return this.numberOfDays
                ? collect(this.numberOfDays[0].data)
                      .pluck('period')
                      .all()
                : [];
        }
    },
    methods: {
        getTypeName(type) {
            return type === 'nav'
                ? type.toUpperCase()
                : type.charAt(0).toUpperCase() + type.slice(1);
        }
    }
};
</script>

<style>
</style>
