<template>
    <div class="md:overflow-x-auto md:scrolling-auto md:mx-4">
        <!--<p>Closing Price Information as of {{ asOfDate }}</p>-->
        <table class="tickers-block table-auto w-full my-6">
            <thead>
                <tr class="header-row">
                    <td class="w-1/12 px-4 py-2 align-bottom">
                        <strong>Ticker</strong>
                    </td>
                    <td class="w-2/12 px-4 py-2 align-bottom">
                        <strong>Fund Name</strong>
                    </td>
                    <td class="w-2/12 px-4 py-2 align-bottom">
                        <strong>Asset Class</strong>
                    </td>
                    <td class="w-2/12 px-4 py-2 text-center align-bottom">
                        <strong>AUM</strong>
                    </td>
                    <td class="w-2/12 px-4 py-2 text-center align-bottom">
                        <strong>Nav</strong>
                    </td>
                    <td class="w-2/12 px-4 py-2 text-center align-bottom">
                        <strong>Market Price</strong>
                    </td>
                    <td class="w-2/12 px-4 py-2 text-center align-bottom">
                        <strong>Ter</strong>
                    </td>
                    <td class="w-2/12 px-4 py-2 text-center align-bottom">
                        <strong>Factsheet</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(fund, index) of liveFunds"
                    :key="index"
                    :class="{ 'bg-gray-100': index % 2 == 0 }"
                >
                    <td class="border px-4 py-2 text-center">
                        <a @click="gotoFundPage(fund)" class="cursor-pointer">{{
                            fund.ticker
                        }}</a>
                    </td>
                    <td class="border px-4 py-2">
                        <a @click="gotoFundPage(fund)" class="cursor-pointer">{{
                            fund.post_title
                        }}</a>
                    </td>
                    <td class="border px-4 py-2">
                        {{ getAssetClass(fund) }}
                    </td>
                    <td class="border px-4 py-2 text-center">
                        <money-format
                            :value="fund.fund_aum"
                            :hide-subunits="true"
                        >
                        </money-format>
                    </td>
                    <td class="border px-4 py-2 text-center">
                        <money-format :value="fund.nav" :hide-subunits="false">
                        </money-format>
                    </td>
                    <td class="border px-4 py-2 text-center">
                        <money-format
                            :value="fund.market_price"
                            :hide-subunits="false"
                        >
                        </money-format>
                    </td>
                    <td class="border px-4 py-2 text-center">
                        {{ getTer(fund) }}%
                    </td>
                    <td class="border px-4 py-2 text-center">
                        <a
                            :href="fund.factsheet"
                            class="download justify-center items-center cursor-pointer"
                            target="_blank"
                            >PDF</a
                        >
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import collect from 'collect.js';

export default {
    mounted() {
        this.getFundData().catch((error) => console.log(error));
    },
    computed: {
        ...mapGetters('funds', ['funds']),
        asOfDate() {
            return this.funds && this.funds.length
                ? moment.utc(this.funds[0].as_of_date).format('M/D/YYYY')
                : '';
        },
        liveFunds() {
            return collect(this.funds).filter(fund => {
                return fund.nav;
            }).all();
        }
    },
    methods: {
        ...mapActions('funds', ['getFundData']),
        gotoFundPage(fund) {
            window.location.href = `/etf/${fund.post_name}`;
        },
        getAssetClass(fund) {
            return fund.asset_class ?? fund.backup_asset_class;
        },
        getTer(fund) {
            const ter =
                new Number(fund.gross_expense_ratio).toFixed(2) ??
                fund.backup_ter ??
                '';
            return String(ter).replace('%', '');
        },
    },
};
</script>
<style></style>
