<template>
    <div id="performance" class="section mx-auto w-1200 performance">
        <a class="anchor" id="anchor"></a>
        <h2>ETF Performance & Index History</h2>
        <div class="md:overflow-x-auto md:scrolling-auto">
            <table class="funds-block table-fixed w-full mb-6">
                <thead>
                    <tr class="header-row">
                        <td class="w-3/12 px-4 py-2 align-bottom">
                            <strong
                                >Monthly<br />
                                <as-of-date
                                    :ticker="ticker"
                                    :margin="false"
                                    mode="monthly"
                            /></strong>
                        </td>
                        <td class="w-1/12 px-4 py-2 text-center align-bottom">
                            <strong>1 Month</strong>
                        </td>
                        <td class="w-1/12 px-4 py-2 text-center align-bottom">
                            <strong>3 Months</strong>
                        </td>
                        <td class="w-1/12 px-4 py-2 text-center align-bottom">
                            <strong>6 Months</strong>
                        </td>
                        <td class="w-1/12 px-4 py-2 text-center align-bottom">
                            <strong>YTD</strong>
                        </td>
                        <td
                            colspan="2"
                            class="w-2/12 py-2 text-center align-bottom"
                        >
                            <table class="w-full">
                                <tr>
                                    <td colspan="2">
                                        <strong class="text-xs"
                                            >Since Inception ({{
                                                inceptionDate
                                                    | moment(
                                                        'timezone',
                                                        'America/New_York',
                                                        'M/D/YY'
                                                    )
                                            }})</strong
                                        >
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Price Return</strong>
                                    </td>
                                    <td>
                                        <strong>Total Return</strong>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(row, mindex) in monthlyPerformance"
                        :key="mindex"
                        :class="{ 'bg-gray-100': mindex % 2 }"
                    >
                        <td class="border px-4 py-2">
                            {{ row.name }}
                        </td>
                        <td class="border px-4 py-2 text-center">
                            {{ row.one_month | decimal | nulldash('%') }}
                        </td>
                        <td class="border px-4 py-2 text-center">
                            {{ row.three_months | decimal | nulldash('%') }}
                        </td>
                        <td class="border px-4 py-2 text-center">
                            {{ row.six_months | decimal | nulldash('%') }}
                        </td>
                        <td class="border px-4 py-2 text-center">
                            {{ row.ytd | decimal | nulldash('%') }}
                        </td>
                        <td class="border px-4 py-2 text-center">
                            {{ row.since_inception | decimal | nulldash('%') }}
                        </td>
                        <td class="border px-4 py-2 text-center">
                            {{
                                row.cumulative_since_inception
                                    | decimal
                                    | nulldash('%')
                            }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="md:overflow-x-auto md:scrolling-auto">
            <table class="funds-block table-fixed w-full mb-6">
                <thead>
                    <tr class="header-row">
                        <td class="w-3/12 px-4 py-2 align-bottom">
                            <strong
                                >Quarterly<br />
                                <as-of-date
                                    :ticker="ticker"
                                    :margin="false"
                                    mode="quarterly"
                            /></strong>
                        </td>
                        <td class="w-1/12 px-4 py-2 text-center align-bottom">
                            <strong>1 Year</strong>
                        </td>
                        <td class="w-1/12 px-4 py-2 text-center align-bottom">
                            <strong>3 Years</strong>
                        </td>
                        <td class="w-1/12 px-4 py-2 text-center align-bottom">
                            <strong>5 Years</strong>
                        </td>
                        <td class="w-1/12 px-4 py-2 text-center align-bottom">
                            <strong>10 Years</strong>
                        </td>
                        <td
                            colspan="2"
                            class="w-1/12 py-2 text-center align-bottom"
                        >
                            <table class="w-full">
                                <tr>
                                    <td colspan="2">
                                        <strong class="text-xs"
                                            >Since Inception ({{
                                                inceptionDate
                                                    | moment(
                                                        'timezone',
                                                        'America/New_York',
                                                        'M/D/YY'
                                                    )
                                            }})</strong
                                        >
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Price Return</strong>
                                    </td>
                                    <td>
                                        <strong>Total Return</strong>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(row, qindex) in quarterlyPerformance"
                        :key="qindex"
                        :class="{ 'bg-gray-100': qindex % 2 }"
                    >
                        <td class="border px-4 py-2">
                            {{ row.name }}
                        </td>
                        <td class="border px-4 py-2 text-center">
                            {{ row.one_year | decimal | nulldash('%') }}
                        </td>
                        <td class="border px-4 py-2 text-center">
                            {{ row.three_year | decimal | nulldash('%') }}
                        </td>
                        <td class="border px-4 py-2 text-center">
                            {{ row.five_year | decimal | nulldash('%') }}
                        </td>
                        <td class="border px-4 py-2 text-center">
                            {{ row.ten_year | decimal | nulldash('%') }}
                        </td>
                        <td class="border px-4 py-2 text-center">
                            {{ row.since_inception | decimal | nulldash('%') }}
                        </td>
                        <td class="border px-4 py-2 text-center">
                            {{
                                row.cumulative_since_inception
                                    | decimal
                                    | nulldash('%')
                            }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div
            class="footer-text mt-8"
            v-if="performanceFooterText"
            v-html="performanceFooterText"
        ></div>
    </div>
    <!-- end .performance -->
</template>

<script>
import { mapGetters } from 'vuex';
import collect from 'collect.js';

export default {
    props: ['ticker', 'performanceFooterText'],
    computed: {
        ...mapGetters('funds', ['fundDetail']),
        fund() {
            return this.fundDetail(this.ticker);
        },
        inceptionDate() {
            return this.fund ? this.fund.inception_date : '';
        },
        apiMonthly() {
            return this.fund
                ? this.addTypes(this.fund.latest_performance.monthly)
                : [];
        },
        apiQuarterly() {
            return this.fund
                ? this.addTypes(this.fund.latest_performance.quarterly)
                : [];
        },
        monthlyPerformance() {
            return this.fund
                ? this.transform(this.apiMonthly, this.fund.monthly_performance)
                : [];
        },
        quarterlyPerformance() {
            return this.fund
                ? this.transform(
                      this.apiQuarterly,
                      this.fund.quarterly_performance
                  )
                : [];
        },
    },
    methods: {
        transform(apiSource, wpSource) {
            return wpSource
                ? collect(apiSource)
                      .merge(
                          collect(wpSource)
                              .filter((row) => !row.commentary_only)
                              .map((row) => {
                                  return {
                                      name: row.name,
                                      one_month: row['1_month'],
                                      three_months: row['3_months'],
                                      six_months: row['6_months'],
                                      ytd: row.ytd,
                                      one_year: row['1_year'],
                                      three_year: row['3_years'],
                                      five_year: row['5_years'],
                                      since_inception: row.since_inception,
                                      cumulative_since_inception:
                                          row.cumulative_since_inception,
                                  };
                              })
                              .all()
                      )
                      .all()
                : apiSource;
        },
        addTypes(source) {
            return collect(source)
                .filter((type) => type)
                .map((type, key) => {
                    if (!type) {
                        return [];
                    }
                    type.name = `${this.fund.name} at ${
                        key === 'nav' ? 'NAV' : 'Market Price'
                    }`;
                    return type;
                })
                .values()
                .all();
        },
    },
};
</script>

<style>
</style>
