<template>
    <div class="fund-details">
        <h2 class="mb-0">Fund Details</h2>
        <p>
            As of
            {{
                fund.as_of_date
                    | moment('timezone', 'America/New_York', 'M/D/YYYY')
            }}
        </p>
        <ul>
            <li>
                <strong>Fund Name</strong> <span>{{ fund.name }}</span>
            </li>
            <li>
                <strong>Ticker Symbol</strong> <span>{{ fund.ticker }}</span>
            </li>
            <li>
                <strong>CUSIP</strong> <span>{{ fund.cusip }}</span>
            </li>
            <li>
                <strong>Inception Date</strong>
                <span>{{
                    fund.inception_date
                        | moment('timezone', 'America/New_York', 'M/D/YYYY')
                }}</span>
            </li>
            <li>
                <strong>Asset Class</strong>
                <span>{{ fund.asset_class }}</span>
            </li>
            <li>
                <strong>Bloomberg Index Tracker</strong>
                <span>{{ fund.bloomberg_index_tracker }}</span>
            </li>
            <li>
                <strong>Primary Exchange</strong>
                <span>{{ fund.fund_listing_exchange }}</span>
            </li>
            <li>
                <strong>Management Style</strong>
                <span>{{ fund.management_style }}</span>
            </li>
            <li>
                <strong>Fund Assets</strong>
                <span
                    ><money-format :value="fund.fund_aum" :hide-subunits="true">
                    </money-format
                ></span>
            </li>
            <li>
                <strong>Current Net Asset Value</strong>
                <span
                    ><money-format :value="fund.nav" :hide-subunits="false">
                    </money-format
                ></span>
            </li>
            <li>
                <strong>NAV 1 Day % Change</strong>
                <span>{{ fund.nav_percent_change | decimal }}%</span>
            </li>
            <li>
                <strong>Market Price</strong>
                <span
                    ><money-format
                        :value="fund.market_price"
                        :hide-subunits="false"
                    >
                    </money-format
                ></span>
            </li>
            <li>
                <strong>Market Price 1 Day % Change</strong>
                <span>{{ fund.marketprice_percent_change | decimal }}%</span>
            </li>
            <li>
                <strong>Premium / (Discount)</strong>
                <span>{{ fund.discount_premium | decimal }}%</span>
            </li>
            <li>
                <strong>Shares Outstanding</strong>
                <span>{{ fund.shares_outstanding | numberFormat }}</span>
            </li>
            <li>
                <strong>Total Expense Ratio (TER)</strong>
                <span>{{ fund.gross_expense_ratio | decimal }}%</span>
            </li>
            <li>
                <strong>Total Holdings</strong>
                <span>{{ fund.num_holdings }}</span>
            </li>
            <li>
                <strong>Turnover Ratio</strong>
                <span>{{ fund.turnover | decimal | nulldash('%') }}</span>
            </li>
            <li>
                <strong>Median Bid-Ask Spread</strong>
                <span>{{ (fund.median_bid_ask * 100) | decimal(5) }}%</span>
            </li>
            <li>
                <strong>30-Day SEC Yield*</strong>
                <span>{{ fund.sec_yield | decimal | nulldash('%') }}</span>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

export default {
    props: ['ticker'],
    data() {
        return {
            fund: {
                name: '',
                ticker: '',
                as_of_date: '',
                inception_date: '',
                cusip: '',
                asset_class: '',
                bloomberg_index_tracker: '',
                management_style: '',
                fund_listing_exchange: '',
                num_holdings: '',
                fund_aum: 0,
                nav: 0,
                market_price: 0,
                shares_outstanding: 0,
                gross_expense_ratio: 0,
                turnover: '',
                sec_yield: ''
            }
        };
    },
    computed: {
        ...mapGetters('funds', ['fundDetail']),
        calendarIcon() {
            return `${window.theme_path.path}/templates/partials/icon-calendar.svg`;
        }
    },
    mounted() {
        this.getFundDetails(this.ticker)
            .then(details => (this.fund = details[this.ticker]))
            .catch(error => console.log(error.message));
    },
    methods: {
        ...mapActions('funds', ['getFundDetails'])
    }
};
</script>

<style></style>
