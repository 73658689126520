<template>
    <highcharts
        :constructor-type="'stockChart'"
        :options="premChartOptions"
        ref="chart"
    ></highcharts>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    props: ['ticker'],
    data() {
        return {
            premChartOptions: {
                chart: {
                    events: {
                        load() {
                            this.showLoading();
                        },
                    },
                },
                credits: {
                    enabled: false,
                },
                title: {
                    text: undefined,
                },
                yAxis: {
                    title: {
                        text: 'Percentage',
                    },
                },

                xAxis: {
                    type: 'datetime',
                    labels: {
                        rotation: -45,
                        formatter: function () {
                            return Highcharts.dateFormat(
                                '%m/%d/%Y',
                                this.value
                            );
                        },
                    },
                    accessibility: {
                        rangeDescription: 'Date',
                    },
                },

                legend: {
                    layout: 'vertical',
                    align: 'right',
                    verticalAlign: 'middle',
                },

                // plotOptions: {
                //     series: {
                //         label: {
                //             connectorAllowed: false
                //         },
                //         pointStart: 2010
                //     }
                // },

                series: [
                    {
                        name: 'Discount Premium (%)',
                        data: [],
                    },
                ],

                responsive: {
                    rules: [
                        {
                            condition: {
                                maxWidth: 1200,
                            },
                            chartOptions: {
                                legend: {
                                    layout: 'horizontal',
                                    align: 'center',
                                    verticalAlign: 'bottom',
                                },
                            },
                        },
                    ],
                },
            },
        };
    },
    computed: {
        ...mapGetters('funds', ['premiumDiscount']),
    },
    mounted() {
        this.getPremiumDiscount(this.ticker)
            .then(() => {
                this.premChartOptions.series[0].data = this.premiumDiscount;
                this.$refs.chart.chart.hideLoading();
            })
            .catch((error) => console.log(error.message));
    },
    methods: {
        ...mapActions('funds', ['getPremiumDiscount']),
    },
};
</script>

<style></style>
