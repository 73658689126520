<template>
    <div :class="{ 'mb-4': hasMarginBottom }" class="as-of-date">
        As of
        {{ as_of_date | moment('timezone', 'America/New_York', 'M/D/YYYY') }}
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment-timezone';

export default {
    props: ['ticker', 'hasMargin', 'mode'],
    computed: {
        ...mapGetters('funds', ['fundDetail']),
        as_of_date() {
            const fund = this.fundDetail(this.ticker);
            const date =
                this.mode == 'monthly' || this.mode == 'quarterly'
                    ? fund?.latest_performance[this.mode]?.market?.processed_at
                    : fund?.as_of_date;
            return fund ? this.calculateDate(date) : '';
        },
        hasMarginBottom() {
            return this.hasMargin === undefined || this.hasMargin === true;
        },
    },
    methods: {
        calculateDate(date) {
            return date;
        },
    },
};
</script>

<style>
</style>
