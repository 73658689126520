<template>
  <lottie :options="animationDataLoaded" :height="100" :width="100" />
</template>

<script>
import Lottie from "vue-lottie";

export default {
  components: {
    Lottie,
  },
  props: ["animation"],
  data() {
    return {
      defaultOptions: {
        animationData: {},
        loop: true,
      },
      animationSpeed: 1,
      autoPlay: true,
    };
  },
  created() {
    this.animationInit()
  },
  computed: {
    animationDataLoaded() {
      return this.defaultOptions
    }
  },
  methods: {
    animationInit() {
      this.defaultOptions.animationData = JSON.parse(
        this.animation.replace(/&quot;/g, '"')
      )
    },
  },
};
</script>

<style>
</style>
