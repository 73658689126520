import axios from 'axios';
import collect from 'collect.js';

export default {
    getFundData({ commit, getters }) {
        return new Promise((resolve, reject) => {
            axios
                .get('funds')
                .then(response => {
                    if (response && response.data && response.data.funds) {
                        const apiFunds = collect(response.data.funds);
                        const wpFunds = collect(window.fundlist).map(fund => {
                            const apiFund = apiFunds.firstWhere(
                                'ticker',
                                fund.ticker
                            );
                            return collect(fund)
                                .merge(collect(apiFund).all())
                                .all();
                        });
                        commit('FUNDS', wpFunds.all());
                        resolve(getters.funds);
                    }
                })
                .catch(response => {
                    reject(response);
                });
        });
    },

    getFundDetails({ commit, state }, ticker) {
        return new Promise((resolve, reject) => {
            if (!state.fundDetails[ticker]) {
                axios
                    .get(`funds/${ticker}`)
                    .then(response => {
                        if (response && response.data && response.data.fund) {
                            let detailsObj = {
                                ...state.fundDetails
                            };
                            detailsObj[ticker] = {
                                ...window.fund_details,
                                ...response.data.fund
                            };
                            commit('FUND_DETAILS', detailsObj);
                            if (response.data.holdings) {
                                let holdingsObj = {
                                    ...state.topHoldings
                                };
                                holdingsObj[ticker] = response.data.holdings;
                                commit('TOP_HOLDINGS', holdingsObj);
                            }
                            resolve(detailsObj);
                        }
                    })
                    .catch(response => {
                        reject(response);
                    });
            } else {
                resolve(state.fundDetails);
            }
        });
    },

    getNavMarketData({ commit, state }, ticker) {
        return new Promise((resolve, reject) => {
            if (!state.navMarket.nav.length && !state.navMarket.market.length) {
                axios
                    .get(`nav_market/${ticker}`)
                    .then(response => {
                        if (response && response.data) {
                            commit('NAV_MARKET', response.data);
                            resolve(response.data);
                        }
                    })
                    .catch(response => {
                        reject(response);
                    });
            } else {
                resolve(state.navMarket);
            }
        });
    },

    getPremiumDiscount({ commit, state }, ticker) {
        return new Promise((resolve, reject) => {
            if (!state.premiumDiscount.length) {
                axios
                    .get(`premium_discount/${ticker}`)
                    .then(response => {
                        if (response && response.data) {
                            commit('PREMIUM_DISCOUNT', response.data);
                            resolve(response.data);
                        }
                    })
                    .catch(response => {
                        reject(response);
                    });
            } else {
                resolve(state.premiumDiscount);
            }
        });
    }
};
