var $ = require('jquery');
window.jQuery = $;
window.$ = $;
window.ctas = [];
require('./vue');
// import ModalVideo from 'modal-video';
// import { TimelineMax } from "gsap";
import slick from 'slick-carousel';
import 'waypoints/lib/noframework.waypoints.min.js';

(function () {
    function elSlider() {
        if ($('.carousel').length) {
            $('.carousel').not('.slick-initialized')
                .on('init reInit afterChange', function (
                    event,
                    slick,
                    currentSlide,
                    nextSlide
                ) {
                    var i = (currentSlide ? currentSlide : 0) + 1;
                    $('.carousel-current').text(i + ' of ' + slick.slideCount);
                })
                .slick({
                    dots: false,
                    infinite: true,
                    slidesToShow: 1,
                    arrows: true,
                    fade: true,
                    prevArrow: '.control.prev',
                    nextArrow: '.control.next',
                    adaptiveHeight: true
                });
        }
    }
    elSlider();

    if ($('.slider').length) {
        $('.slider').not('.slick-initialized')
            .on('init reInit afterChange', function (
                event,
                slick,
                currentSlide,
                nextSlide
            ) {
                var i = (currentSlide ? currentSlide : 0) + 1;
                $('.carousel-current').text(i + ' of ' + slick.slideCount);
            })
            .slick({
                dots: false,
                infinite: true,
                slidesToShow: 3,
                arrows: true,
                fade: false,
                prevArrow: '.control.prev',
                nextArrow: '.control.next',
                adaptiveHeight: true,
                responsive: [
                    {
                        breakpoint: 960,
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 1
                        }
                    }
                ]
            });
    }

    /* Mobile menu */
    $('.menu-icon').click(function () {
        $(this).toggleClass('on');
        $('.mobile-menu').slideToggle();
        $('body').toggleClass('overflow');
    });

    $('.mobile-menu')
        .find('.icon-toggle')
        .on('click', function () {
            $(this).parent().toggleClass('on');
            $(this).next('ul').slideToggle();
        });

    $('.search.btn, .search-icon').on('click', function () {
        $('.modal.search').fadeIn(300);
    });
    $('.buy-now').on('click', function () {
        $('.modal.buy').fadeIn(300);
    });
    $('.modal .close').on('click', function () {
        let video = $(this).parent().find('#video_iframe');
        if(video){
            video.attr('src', 'https://emles.com');
        }
        $('.modal').fadeOut(300);
    });

    // Drop down
    $('ul.menu > li').on('hover', function () {
        $('body').toggleClass('hover-active');
    });

    /* External link warning popup */
    // Wordpress menu item has ACF data-attribute field. Adds data-prevent="true" that will trigger the warning popup. (ie insights > resource center)
    $("a[data-prevent='true']").on('click', onExitModal);
    // data-prevent="false" to any link with target="_blank" ignores the warning (ie the 'Buy ETF' external Broker links)
    $("a[target='_blank']").on('click',onExitModal);

    $('.modal .exit, .stay').on('click', function () {
        $('.modal').fadeOut(300);
    });

    $("[data-video-modal]").on('click', openVideoModal);
})();

function openVideoModal(e){
    e.preventDefault();
    let id = $(this).data('video-modal');
    $('#video_iframe').attr('src', `https://player.vimeo.com/video/${id}`);

    let links =  $(this).data('modal-links');
    let disclosure = $(this).data('disclosure-copy');
    let formated_disclosure = JSON.parse(disclosure).replace(/&quot;/g,'"');
    //remove any previous links in the modal
    $('.modal-links').empty();
    $('.modal-disclosure').empty();

    for(var i = 0; i < links.length; i++) {
        $(`<a class="px-4" href="${links[i][1]}" />`).text(links[i][0]).appendTo($('.modal-links'));
    }
   
    $('.modal-disclosure').html(formated_disclosure);
    $('.modal.video').fadeIn(300).focus();
}

function onExitModal(e){
    let prevent = $(this).data('prevent');
    if(prevent != false){
        e.preventDefault();
        $('.modal.exit').fadeIn(300);
        let linkUrl = $(this).attr('href') || ''
        let target = $(this).prop('target');
        if(target.toLowerCase() == '_blank'){
            // needed to reset to _blank since insight > resource center sets the link to _self
            $('.modal .exit, .continue').attr('target','_blank');
        } else {
            $('.modal .exit, .continue').attr('target','_self');
        }
        $('.modal .exit, .continue').attr("href", linkUrl);
        $('.modal .exit, .continue').on('click', function () {
            $('.modal').fadeOut(300);
        })
    }
    
}

$(function () {
    if ($(window).width() < 768) {
        $('.carousel-wrap .slider').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            prevArrow: '.control.prev',
            nextArrow: '.control.next',
            adaptiveHeight: true
        });

        $('.carousel-wrap .slider').on(
            'init reInit afterChange load',
            function (event, slick, currentSlide, nextSlide) {
                var i = (currentSlide ? currentSlide : 0) + 1;
                $('.carousel-current').text(i + ' of ' + slick.slideCount);
            }
        );
    } else {
        $('.carousel-wrap .slider').not('.slick-initialized').slick({
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: true,
            prevArrow: '.control.prev',
            nextArrow: '.control.next',
            adaptiveHeight: true
        });

        $('.carousel-wrap .slider').on(
            'init reInit afterChange load',
            function (event, slick, currentSlide, nextSlide) {
                var i = (currentSlide ? currentSlide : 0) + 1;
                $('.carousel-current').text(i + ' of ' + slick.slideCount);
            }
        );
    }

    $('.infoline ul li a').on('click', function (event) {
        // Make sure this.hash has a value before overriding default behavior
        if (this.hash !== '') {
            // Prevent default anchor click behavior
            event.preventDefault();

            // Store hash
            var hash = this.hash;

            // Using jQuery's animate() method to add smooth page scroll
            // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
            $('html, body').animate(
                {
                    scrollTop: $(hash).offset().top - 100
                },
                800,
                function () {
                    // Add hash (#) to URL when done scrolling (default click behavior)
                    //window.location.hash = hash;
                }
            );
        } // End if
    });

    // adds waypoints for blue bar on etf single page

    if ($('body').hasClass('single-etf')) {
        newWaypoint('key-fund-info', 'none');
        newWaypoint('performance', 'key-fund-info');
        newWaypoint('holdings', 'performance');
        newWaypoint('pricing', 'holdings');
        newWaypoint('premiums', 'pricing');
        newWaypoint('dist-cap-gains', 'premiums');

        initCTAs();
    }
});

// waypoint function for single etf template
function newWaypoint(elementId, upSection) {
    var elementIdWaypoint = new Waypoint({
        element: document.getElementById(elementId),
        handler: function (direction) {
            // console.log(this.element.id + ' going ' + direction);
            if (direction === 'down') {
                $('.infoline li a').removeClass('active');
                $('.infoline li.' + this.element.id + ' a').addClass('active');
            } else {
                $('.infoline li a').removeClass('active');
                $('.infoline li.' + upSection + ' a').addClass('active');
            }
        },
        offset: 220
    });
}

function initCTAs() {
    if (hbspt && window.ctas !== undefined && window.ctas.length) {
        for (let i = 0; i < window.ctas.length; i++) {
            const cta = window.ctas[i];
            hbspt.cta.load(cta.portal, cta.id, {});
        }
    }
}
