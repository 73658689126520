<template>
    <div>
        <as-of-date :ticker="ticker"></as-of-date>
        <div class="md:overflow-x-auto md:scrolling-auto">
            <table class="dist-caps-block table-auto w-full mb-6">
                <thead>
                    <tr class="header-row">
                        <td class="w-1/8 px-4 py-2 align-bottom">
                            <strong>Ex-Date</strong>
                        </td>
                        <td class="w-1/8 px-4 py-2 align-bottom">
                            <strong>Ordinary Income</strong>
                        </td>
                        <td class="w-1/8 px-4 py-2 align-bottom">
                            <strong>Short Term Capital Gain</strong>
                        </td>
                        <td class="w-1/8 px-4 py-2 align-bottom">
                            <strong>Long Term Capital Gain</strong>
                        </td>
                        <td class="w-1/8 px-4 py-2 align-bottom">
                            <strong>Return of Capital</strong>
                        </td>
                        <td class="w-1/8 px-4 py-2 align-bottom">
                            <strong>Total Distribution</strong>
                        </td>
                    </tr>
                </thead>
                <tbody v-if="table.length">
                    <tr
                        v-for="(row, index) in table"
                        :key="index"
                        :class="{ 'bg-gray-100': index % 2 }"
                    >
                        <td class="border px-4 py-2">
                            {{
                                row.ex_date
                                    | moment(
                                        'timezone',
                                        'America/New_York',
                                        'M/D/YYYY'
                                    )
                            }}
                        </td>
                        <td class="border px-4 py-2">
                            <money-format
                                v-if="row.ordinary_income"
                                :value="row.ordinary_income"
                                :hide-subunits="false"
                                :supplemental-precision="4"
                            >
                            </money-format>
                            <span v-else>-</span>
                        </td>
                        <td class="border px-4 py-2">
                            <money-format
                                v-if="row.short_term_capital_gain"
                                :value="row.short_term_capital_gain"
                                :hide-subunits="false"
                                :supplemental-precision="4"
                            >
                            </money-format>
                            <span v-else>-</span>
                        </td>
                        <td class="border px-4 py-2">
                            <money-format
                                v-if="row.long_term_capital_gain"
                                :value="row.long_term_capital_gain"
                                :hide-subunits="false"
                                :supplemental-precision="4"
                            >
                            </money-format>
                            <span v-else>-</span>
                        </td>
                        <td class="border px-4 py-2">
                            <money-format
                                v-if="row.return_of_capital"
                                :value="row.return_of_capital"
                                :hide-subunits="false"
                                :supplemental-precision="4"
                            >
                            </money-format>
                            <span v-else>-</span>
                        </td>
                        <td class="border px-4 py-2">
                            <money-format
                                v-if="row.total_distribution"
                                :value="row.total_distribution"
                                :hide-subunits="false"
                                :supplemental-precision="4"
                            >
                            </money-format>
                            <span v-else>-</span>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td class="border px-4 py-2">-</td>
                        <td class="border px-4 py-2">-</td>
                        <td class="border px-4 py-2">-</td>
                        <td class="border px-4 py-2">-</td>
                        <td class="border px-4 py-2">-</td>
                        <td class="border px-4 py-2">-</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import collect from 'collect.js';

export default {
    props: ['ticker'],
    computed: {
        ...mapGetters('funds', ['fundDetail']),
        fund() {
            return this.fundDetail(this.ticker);
        },
        table() {
            return this.fund
                ? collect(this.fund.distributions)
                      .map((item) => {
                          return {
                              ex_date: item.dividends
                                  ? item.dividends.ex_date
                                  : '-',
                              ordinary_income: item.dividends
                                  ? item.dividends.ordinary_income
                                  : 0,
                              short_term_capital_gain: item.capital_gains
                                  ? item.capital_gains.short_term_capital_gain
                                  : 0,
                              long_term_capital_gain: item.capital_gains
                                  ? item.capital_gains.long_term_capital_gain
                                  : 0,
                              return_of_capital: item.capital_gains
                                  ? item.capital_gains.return_of_capital
                                  : 0,
                              total_distribution:
                                  this.calcTotalDistributions(item),
                          };
                      })
                      .all()
                : [];
        },
    },
    methods: {
        calcTotalDistributions(item) {
            return collect([])
                .when(item.dividends, (values) =>
                    values.push(item.dividends.ordinary_income)
                )
                .when(item.capital_gains, (values) =>
                    values.merge(
                        collect(item.capital_gains)
                            .only([
                                'short_term_capital_gain',
                                'long_term_capital_gain',
                                'return_of_capital',
                            ])
                            .values()
                            .all()
                    )
                )
                .filter((value) => value !== null)
                .sum();
        },
    },
};
</script>

<style>
</style>
