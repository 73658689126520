import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import funds from './modules/funds';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    storage: window.sessionStorage
});

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    modules: {
        funds
    },
    // plugins: [vuexLocal.plugin],
    strict: debug
});
