<template>
    <table class="top-holdings-block table-auto w-full mb-6">
        <thead>
            <tr class="header-row">
                <td class="w-2/12 px-4 py-2 align-bottom">
                    <strong>Net Assets (%)</strong>
                </td>
                <td class="w-1/12 px-4 py-2 align-bottom">
                    <strong>Ticker</strong>
                </td>
                <td class="w-3/12 px-4 py-2 align-bottom">
                    <strong>Name</strong>
                </td>
                <td class="w-2/12 px-4 py-2 align-bottom">
                    <strong>Identifier</strong>
                </td>
                <td class="w-2/12 px-4 py-2 align-bottom">
                    <strong>Shares Held</strong>
                </td>
                <td class="w-2/12 px-4 py-2 align-bottom">
                    <strong>Market Value ($)</strong>
                </td>
            </tr>
        </thead>
        <tbody>
            <tr
                v-for="(holding, index) in holdings"
                :key="index"
                :class="{ 'bg-gray-100': index % 2 == 0 }"
            >
                <td class="border px-4 py-2">
                    {{ holding.net_assets | decimal(2) }}%
                </td>
                <td class="border px-4 py-2">
                    {{ holding.ticker }}
                </td>
                <td class="border px-4 py-2 uppercase">{{ holding.name }}</td>
                <td class="border px-4 py-2">
                    {{ holding.identifier }}
                </td>
                <td class="border px-4 py-2">
                    {{ holding.shares_held | numberFormat }}
                </td>
                <td class="border px-4 py-2">
                    <money-format
                        :value="holding.market_value"
                        :hide-subunits="true"
                    >
                    </money-format>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: ['ticker'],
    computed: {
        ...mapGetters('funds', ['topHoldings']),
        holdings() {
            const holdings = this.topHoldings(this.ticker);
            return holdings ? holdings : [];
        },
    },
};
</script>

<style></style>
