import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const state = () => ({
    funds: [],
    fundDetails: {},
    topHoldings: {},
    navMarket: {
        nav: [],
        market: []
    },
    premiumDiscount: []
});

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
