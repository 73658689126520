import Vue from 'vue';
import axios from 'axios';
import store from './store';
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
Vue.use(VueMoment, {
    moment,
});
import Highcharts from 'highcharts';
window.Highcharts = Highcharts;
import HighchartsVue from 'highcharts-vue';
import stockInit from 'highcharts/modules/stock'
Vue.use(HighchartsVue);
stockInit(Highcharts);

window.dev_mode = process.env.NODE_ENV === 'development';

axios.defaults.baseURL =
    process.env.NODE_ENV === 'development'
        ? 'https://emles-api.test/api/'
        : 'https://api.emles.com/api/';

import MoneyFormat from 'vue-money-format';
Vue.component('money-format', MoneyFormat);

import FundList from './components/fund-list';
Vue.component('fund-list', FundList);
import FundDetails from './components/fund-details';
Vue.component('fund-details', FundDetails);
import TopHoldings from './components/top-holdings';
Vue.component('top-holdings', TopHoldings);
import NavMartketChart from './components/nav-market-chart';
Vue.component('nav-market-chart', NavMartketChart);
import PremiumDiscountChart from './components/premium-discount-chart';
Vue.component('premium-discount-chart', PremiumDiscountChart);
import PremiumDiscountTable from './components/premium-discount-table';
Vue.component('premium-discount-table', PremiumDiscountTable);
import AsOfDate from './components/as-of-date';
Vue.component('as-of-date', AsOfDate);
import Performance from './components/performance';
Vue.component('performance', Performance);
import Distributions from './components/distributions';
Vue.component('distributions', Distributions);

import Animation from './components/animation';
Vue.component('animation', Animation);

import numberFormat from './filters/number-format';
Vue.filter('numberFormat', numberFormat);
import decimal from './filters/decimal';
Vue.filter('decimal', decimal);
import nullDash from './filters/nulldash';
Vue.filter('nulldash', nullDash);

if ($('#app').length) {
    const app = new Vue({
        store
    }).$mount('#app');
}
